/* eslint-disable func-names */
<template>
  <b-row>
    <b-col
      v-if="hasOfferModule == 0"
      cols="12"
      md="12"
    >
      <b-card>
        <div class="not-allow-module">
          <div class="icon text-center">
            <feather-icon
              size="100"
              icon="AlertTriangleIcon"
            />
            <h3 class="pt-2">
              This Module is not active
            </h3>
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col
      v-else
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="userForm.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Merchant"
                label-for="merch"
              >
                <v-select
                  id="merch"
                  v-model="merchant_id"
                  label="name"
                  :filterable="false"
                  :reduce="merchant => merchant"
                  :options="merchants"
                  @search="onSearch"
                  @input="selectMerchant($event)"
                />
                <div v-if="validationErrors.merchant_id">
                  <p
                    class="text-danger"
                  >
                    {{ validationErrors.merchant_id.toString() }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Offer Category"
                label-for="offer_category"
              >
                <v-select
                  id="offer_category"
                  v-model="category_id"
                  label="name"
                  :filterable="false"
                  :reduce="category => category"
                  :options="categories"
                  @search="onCategorySearch"
                  @input="selectCategory($event)"
                />
                <div v-if="validationErrors.category_id">
                  <p
                    class="text-danger"
                  >
                    {{ validationErrors.category_id.toString() }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Price"
                rules="required"
              >
                <b-form-group
                  label="Price"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model.number="userForm.price"
                    :state="getValidationState(validationContext)"
                    type="number"
                    placeholder="Price"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Quantity Limit"
              >
                <b-form-group
                  label="Quantity Limit"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="userForm.quantity_limit"
                    trim
                    type="number"
                    placeholder="Quantity Limit"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Type"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Type"
                >
                  <b-form-checkbox-group
                    v-model="userForm.type"
                    :options="typeOptions"
                    :aria-describedby="ariaDescribedby"
                    buttons
                    class="order-type"
                    button-variant="primary"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                name="Type"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Order Type"
                >
                  <b-form-checkbox-group
                    v-model="userForm.order_type"
                    :options="orderType"
                    :aria-describedby="ariaDescribedby"
                    buttons
                    class="order-type"
                    button-variant="primary"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Discount Amount / Type">
                <b-input-group>
                  <validation-provider
                    #default="validationContext"
                    name="Discount Amount"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="userForm.discount_amount"
                      :state="getValidationState(validationContext)"
                      type="number"
                      placeholder="Discount Amount"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="ZeroCash Type"
                    rules="required"
                  >
                    <b-input-group-append>
                      <b-form-select
                        v-model="userForm.discount_type"
                        :options="amountpercentage"
                        :state="getValidationState(validationContext)"
                        right
                        value-field="value"
                        text-field="text"
                      >
                        <template #first>
                          <b-form-select-option
                            disabled
                            value=""
                          >
                            Select Type
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="ZeroCash Cachback / Type">
                <b-input-group>
                  <validation-provider
                    #default="validationContext"
                    name="ZeroCash Cachback"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="userForm.zerocach_cashback"
                      type="number"
                      placeholder="ZeroCash Cachback"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="ZeroCash Type"
                    rules="required"
                  >
                    <b-input-group-append>
                      <b-form-select
                        v-model="userForm.zerocash_cashback_type"
                        :options="amountpercentage"
                        right
                        value-field="value"
                        text-field="text"
                        :state="getValidationState(validationContext)"
                      >
                        <template #first>
                          <b-form-select-option
                            disabled
                            value=""
                          >
                            Select Type
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Client Cachback / Type">
                <b-input-group>
                  <validation-provider
                    #default="validationContext"
                    name="Client Cachback"
                    rules="required"
                  >
                    <b-form-input
                      v-model.number="userForm.client_cashback"
                      type="number"
                      placeholder="Client Cachback"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <validation-provider
                    #default="validationContext"
                    name="ZeroCash Type"
                    rules="required"
                  >
                    <b-input-group-append>
                      <b-form-select
                        v-model="userForm.client_cashback_type"
                        :options="amountpercentage"
                        :state="getValidationState(validationContext)"
                        right
                        value-field="value"
                        text-field="text"
                      >
                        <template #first>
                          <b-form-select-option
                            disabled
                            value=""
                          >
                            Select Type
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group-append>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="User Limit"
                rules="required"
              >
                <b-form-group
                  label="User Limit"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="userForm.user_limit"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="User Limit"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Expire date"
                rules="required"
              >
                <b-form-group
                  label="Expire date"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="userForm.expire_at"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Description"
                rules="required"
              >
                <b-form-group
                  label="Description"
                  label-for="desc"
                >
                  <b-form-textarea
                    id="desc"
                    v-model="userForm.description"
                    :state="getValidationState(validationContext)"
                    placeholder="Description"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Active"
                label-for="active"
              >
                <b-form-checkbox
                  id="active"
                  v-model="userForm.is_active"
                  checked="true"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              class="mb-2"
            >
              <div v-if="validationErrors.photo">
                <p
                  class="text-danger"
                >
                  {{ validationErrors.photo.toString() }}
                </p>
              </div>
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Offer image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="preview"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        placeholder="Choose image"
                        @change="onChange"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="6"
              class="mb-2"
            >
              <div v-if="validationErrors.cover">
                <p
                  class="text-danger"
                >
                  {{ validationErrors.cover.toString() }}
                </p>
              </div>
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Offer Cover
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      :src="previewCover"
                      height="200"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <div class="d-inline-block">
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        placeholder="Choose image"
                        @change="onChangeCover"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                @click.prevent="addOffer()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { computed } from '@vue/composition-api'
import { required, email } from '@validations'
import axios from 'axios'
import vSelect from 'vue-select'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToggleButton from 'vue-js-toggle-button'
import _ from 'lodash'
import handleFormData from '@/global-compositions/handleFormData'
import offer from '../offers'
import store from '@/store'

Vue.use(ToggleButton)

export default {
  components: { vSelect },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      merchants: [],
      categories: [],
      test: '',
      value: '',
      loader: false,
      validationErrors: {},
      merchant_id: '',
      category_id: '',
      preview: null,
      image: null,
      previewCover: null,
      cover: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    pinToggleIcon() {
      return this.pinFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'userForm.merchant_id': function (value) {
      if (value) {
        if (this.validationErrors.merchant_id) {
          this.validationErrors.merchant_id = ''
        }
      }
    },
    // eslint-disable-next-line func-names
    'userForm.category_id': function (value) {
      if (value) {
        if (this.validationErrors.category_id) {
          this.validationErrors.category_id = ''
        }
      }
    },
  },
  setup() {
    const hasOfferModule = computed(() => store.state.moduleStates.activeModules?.Offer?.status)
    const {
      userForm, typeOptions, amountpercentage, orderType,
    } = offer()
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      userForm,
      typeOptions,
      amountpercentage,
      orderType,
      formData,
      setFormData,
      hasOfferModule,
    }
  },
  mounted() {
    this.showUser()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.userForm.photo = e.target.files[0]
      // console.log(e)
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeCover(e) {
      // eslint-disable-next-line prefer-destructuring
      this.userForm.cover = e.target.files[0]
      // console.log(e)
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.previewCover = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.cover = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    selectMerchant(value) {
      this.userForm.merchant_id = this.merchants.find(owner => owner.id === value.id).id
    },
    selectCategory(value) {
      this.userForm.category_id = this.categories.find(owner => owner.id === value.id).id
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true)
        this.search(loading, search)
      }
    },
    // eslint-disable-next-line func-names
    onCategorySearch: _.debounce(function (search, loading) {
      if (search.length >= 2) {
        loading(true)
        this.searchCategory(loading, search)
      }
    }, 500),
    // eslint-disable-next-line func-names
    search: _.debounce(function (loading, search) {
      axios.get(`get-merchants?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.merchants = res.data.data
        }
        loading(false)
      })
    }, 500),
    searchCategory(loading, search) {
      axios.get(`offer-categories?search=${search}&limit=1000`).then(res => {
        if (search.length >= 2) {
          this.categories = res.data.data
        }
        loading(false)
      })
    },
    showUser() {
      if (this.$route.params.id) {
        axios.get(`offers/${this.$route.params.id}`).then(res => {
          this.preview = res.data?.data.photo
          this.previewCover = res.data?.data.cover
          this.merchant_id = res.data?.data.merchant_name
          this.category_id = res.data?.data.category_name
          this.userForm = res.data.data
          this.userForm.expire_at = res.data.data.expire_at.slice(0, 10)
        })
      } else {
        return false
      }
      return true
    },
    addOffer() {
      if (this.$route.params.id) {
        this.loader = true
        if (this.userForm.is_active === true) {
          this.userForm.is_active = 1
        } else {
          this.userForm.is_active = 0
        }
        this.setFormData(this.userForm)
        this.formData.append('_method', 'PUT')
        axios.post(`offers/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'offers' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            this.loader = true
            if (this.userForm.is_active === true) {
              this.userForm.is_active = 1
            } else {
              this.userForm.is_active = 0
            }
            this.setFormData(this.userForm)
            axios.post('offers', this.formData).then(res => {
              if (res.status === 200 || res.status === 201) {
                this.$router.push({ name: 'offers' })
                this.$toasted.show('Created Successfully', {
                  position: 'top-center',
                  duration: 3000,
                })
              }
            }).catch(error => {
              Object.assign(this.validationErrors, error.response.data.errors)
            }).finally(() => {
              this.loader = false
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-type label.btn.btn-primary.active {
    background-color: #0074c7 !important;
}
</style>
